const regExp = /^[a-zA-Z0-9\s\-]+$/;
const passwordFullRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d.!@#$%^&(){}[\]:;<>,.?/~_+\-=|]{8,72}$/;
const passwordSymbolsRegExp = /^[a-zA-Z\d.!@#$%^&(){}[\]:;<>,.?/~_+\-=|]+$/;
const regExpCheck = (str: string) => regExp.test(str);

const passwordRegExpCheck = (str: string) => passwordFullRegExp.test(str);

const passwordSymbolsRegExpCheck = (str: string) => passwordSymbolsRegExp.test(str);

export { regExpCheck, passwordRegExpCheck, passwordSymbolsRegExpCheck };
