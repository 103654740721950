<template>
    <div :class="theme.auth.auth">
        <ToggleTheme/>
        <div :class="theme.auth.subcontainer">
            <LogoBlock :is-absolute="false" :class="theme.auth.mbL" />
            <form v-show="!showSecondStep" @submit.prevent="showConfirm">
                <div :class="theme.auth.header">
                    <span :class="theme.auth.text">
                        Sign Up
                    </span>
                </div>
                <div :class="theme.auth.content">
                    <div :class="s.codeRow">
                        <span :class="s.codeText">
                            To register, enter the invitation code. If you don't
                            <br />
                            have one, fill out the <span @click="openWindow('https://www.singlebroker.com/get-access')" :class="s.link">Access Request</span> application.
                        </span>
                        <input
                            v-model="signupData.inviteCode"
                            :placeholder="showCodePlaceholder ? 'invite code' : ''"
                            :class="s.codeInput"
                            @focusin="showCodePlaceholder = false"
                            @focusout="showCodePlaceholder = true"
                        />
                    </div>
                    <p :class="[theme.auth.mbL, s.accessType, { [s.active]: signupData.accessType === 'Corporate', [s.disabled]: formDisabled }]">
                        <span @click="setAccessType('Individual')" :class="{ [s.active]: signupData.accessType === 'Individual' }">
                            Individual
                        </span>
                        <span @click="setAccessType('Corporate')" :class="{ [s.active]: signupData.accessType === 'Corporate' }">
                            Corporate
                        </span>
                    </p>
                    <TextInput
                        :title="signupData.accessType === 'Individual' ? 'First Name' : 'Company Name'"
                        :placeholder="signupData.accessType === 'Individual' ? 'First Name' : 'Company Name'"
                        :value="signupData.firstName"
                        @input-text="onInputFirstName($event)"
                        :has-error="errors.firstName"
                        :disabled="formDisabled"
                        :class="theme.auth.mbL"
                        error-text='Please use only Latin characters (a-z, A-Z), numbers (0-9), space (" ") and dash ("-").'
                    />
                    <TextInput
                        v-if="signupData.accessType === 'Individual'"
                        title="Last Name"
                        placeholder="Last Name"
                        :value="signupData.lastName"
                        @input-text="onInputLastName($event)"
                        :has-error="errors.lastName"
                        :disabled="formDisabled"
                        :class="theme.auth.mbL"
                        error-text='Please use only Latin characters (a-z, A-Z), numbers (0-9), space (" ") and dash ("-").'
                    />
                    <TextInput
                        title="Email"
                        placeholder="Your email"
                        :value="signupData.email"
                        @input-text="onInputEmail($event)"
                        :class="theme.auth.mbL"
                        :has-error="errors.email"
                        :disabled="formDisabled"
                        error-text="Please enter valid email"
                    />
                    <div
                        id="passwordSignUp"
                        @focusin="toggleTooltip(true)"
                        @focusout="toggleTooltip(false)"
                        v-tooltip.right="{
                            content: tooltipContent,
                            trigger: 'manual',
                            show: showTooltip,
                        }"
                        :class="theme.auth.mbL"
                    >
                        <TextInput
                            title="Password"
                            placeholder="Your password"
                            :value="signupData.password"
                            @input-text="onInputPassword($event)"
                            is-password
                            :has-error="errors.password"
                            :disabled="formDisabled"
                        />
                    </div>
                    <TextInput
                        title="Confirm password"
                        placeholder="Repeat your password"
                        is-password
                        :value="signupData.passwordConfirm"
                        @input-text="onInputPasswordConfirm($event)"
                        :class="theme.auth.mbL"
                        :has-error="errors.passwordConfirm"
                        :disabled="formDisabled"
                        error-text="Passwords must match"
                    />
                    <Checkbox
                        :value="signupData.acceptUserAgreement"
                        @state-changed="signupData.acceptUserAgreement = !signupData.acceptUserAgreement; errors.acceptUserAgreement = false"
                        :class="theme.auth.mbL"
                        :disabled="formDisabled"
                    >
                        <template>
                            <span :class="[s.checkboxText, { [s.error]: errors.acceptUserAgreement }]">
                                I accept the <span @click="openWindow('https://singlebroker.com/terms-and-conditions/')" :class="s.link">Terms and Conditions</span>, <span @click="openWindow('https://singlebroker.com/privacy-policy/')" :class="s.link">Privacy Policy</span> of SingleBroker GmbH.
                            </span>
                        </template>
                    </Checkbox>
                    <VueRecaptcha
                        v-if="showCaptcha"
                        :sitekey="recaptchaConfig.siteKey"
                        :theme="isThemeDark ? 'dark' : 'light'"
                        size="normal"
                        loadRecaptchaScript
                        language="en"
                        ref="recaptcha"
                        @expired="onRecaptchaExpired"
                        @verify="onRecaptchaVerified"
                        :class="theme.auth.mbL"
                    />
                    <Button
                        is-submit
                        button-type="primary"
                        :button-disabled="isSignUpSubmitDisabled || formDisabled"
                        :class="theme.auth.mbL"
                        wide
                    >
                        <template>
                            Sign up
                        </template>
                    </Button>
                    <div :class="theme.auth.center">
                        <router-link
                            to="/signin"
                        >
                            Already have an account? Sign-In
                        </router-link>
                    </div>
                </div>
            </form>
            <form v-show="showSecondStep" @submit.prevent="signup">
                <div :class="theme.auth.header">
                    <span :class="theme.auth.text">
                        Sign Up Confirmation
                    </span>
                </div>
                <div :class="s.row">
                    <div :class="s.col">
                        <div>
                            <p :class="s.title">Account Type</p>
                            <p :class="s.value">{{ signupData.accessType }}</p>
                        </div>
                        <div>
                            <p :class="s.title">{{ signupData.accessType === 'Individual' ? 'First Name' : 'Company Name' }}</p>
                            <p :class="s.value">{{ signupData.firstName }}</p>
                        </div>
                        <div>
                            <p :class="s.title">Password</p>
                            <p :class="s.value">• • • • • • • • • • •</p>
                        </div>
                    </div>
                    <div :class="s.col">
                        <div>
                            <p :class="s.title">Email</p>
                            <p :class="s.value">{{ signupData.email }}</p>
                        </div>
                        <div
                            v-if="signupData.accessType === 'Individual'"
                        >
                            <p :class="s.title">Last Name</p>
                            <p :class="s.value">{{ signupData.lastName }}</p>
                        </div>
                        <div>
                            <p :class="s.title">Invite Code</p>
                            <p :class="s.value">{{ signupData.inviteCode }}</p>
                        </div>
                    </div>
                </div>
                <div :class="s.buttonsRow">
                    <Button
                        @click="showForm"
                        button-type="info"
                        wide
                    >
                        <template>
                            Cancel
                        </template>
                    </Button>
                    <Button
                        is-submit
                        button-type="primary"
                        :button-disabled="isSignUpSubmitDisabled"
                        wide
                    >
                        <template>
                            Sign up
                        </template>
                    </Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { required, email, maxLength, minLength, sameAs } from 'vuelidate/dist/validators.min';
import { mapGetters } from 'vuex';

import recaptchaConfig from 'Config/recaptcha';
import ToggleTheme from '@/components/Auth/components/ToggleTheme.vue';
import LogoBlock from '@/components/Auth/components/LogoBlock.vue';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { passwordRegExpCheck, passwordSymbolsRegExpCheck, regExpCheck } from 'Lib/utils/regExpRegistrationValidator';
import { regExpInviteCodeCheck } from 'Lib/utils/regExpInviteCodeValidator';
import Button from 'Control/Button.vue';
import Checkbox from 'Control/Checkbox.vue';
import TextInput from 'Control/TextInput.vue';
import theme from 'Theme';

export default {
    name: 'SignUp',
    components: {
        ToggleTheme,
        LogoBlock,
        Button,
        Checkbox,
        TextInput,
        VueRecaptcha,
    },
    data() {
        return {
            formDisabled: true,
            showCodePlaceholder: true,
            showCaptcha: true,
            signupData: {
                email: '',
                password: '',
                passwordConfirm: '',
                firstName: '',
                lastName: '',
                acceptUserAgreement: false,
                recaptchaToken: null,
                inviteCode: '',
                accessType: 'Individual',
            },
            errors: {
                email: false,
                password: false,
                passwordConfirm: false,
                firstName: false,
                lastName: false,
                acceptUserAgreement: false,
                inviteCode: false,
            },
            recaptchaConfig,
            showTooltip: false,
            showFirstNameTooltip: false,
            showLastNameTooltip: false,
            showSecondStep: false,
            hiddenPassword: true,
            hiddenPasswordConfirm: true,
            isSignUpSubmitDisabled: false,
            theme,
        };
    },
    validations() {
        return {
            signupData: {
                email: {
                    required,
                    email,
                    minLength: minLength(5),
                    maxLength: maxLength(100),
                },
                password: {
                    required,
                    minLength: minLength(8),
                    maxLength: maxLength(72),
                    passwordRegExpCheck,
                },
                passwordConfirm: {
                    sameAsPassword: sameAs('password'),
                },
                firstName: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(50),
                    regExpCheck,
                },
                lastName: this.signupData.accessType === 'Individual' ? {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(50),
                    regExpCheck,
                } : {},
                acceptUserAgreement: {
                    sameAs: sameAs(() => true),
                },
                inviteCode: {
                    required,
                    regExpInviteCodeCheck,
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
        checkPasswordLength() {
            return this.signupData.password.length >= 8;
        },
        checkPasswordHasLowerCaseSymbol() {
            return this.signupData.password.toUpperCase() !== this.signupData.password;
        },
        checkPasswordHasUpperCaseSymbol() {
            return this.signupData.password.toLowerCase() !== this.signupData.password;
        },
        checkPasswordHasNumbers() {
            let result = false;
            for (let i = 0; i < 10; i += 1) {
                if (this.signupData.password.indexOf(String(i)) !== -1) {
                    result = true;
                }
            }
            return result;
        },
        checkPasswordSpaces() {
            const { password } = this.signupData;
            return this.signupData.password.length === password.replace(' ', '').length;
        },
    },
    methods: {
        toggleTooltip(flag) {
            this.showTooltip = flag;
        },
        toggleFirstNameTooltip(flag) {
            this.showFirstNameTooltip = flag;
        },
        toggleLastNameTooltip(flag) {
            this.showLastNameTooltip = flag;
        },
        tooltipContent() {
            return `
            <ul style="text-align: left">
                <li style="margin-bottom: 5px">
                    <img class="tooltip-img" src="${this.checkPasswordLength ? require('@/assets/images/icons/yesMarker.svg') : require('@/assets/images/icons/noMarker.svg')}" />
                    <span style="margin-left: 5px">Min 8 characters</span>
                </li>
                <li style="margin-bottom: 5px">
                    <img class="tooltip-img" src="${this.checkPasswordHasLowerCaseSymbol ? require('@/assets/images/icons/yesMarker.svg') : require('@/assets/images/icons/noMarker.svg')}" />
                    <span style="margin-left: 5px">Lower case letter</span>
                </li>
                <li style="margin-bottom: 5px">
                    <img class="tooltip-img" src="${this.checkPasswordHasUpperCaseSymbol ? require('@/assets/images/icons/yesMarker.svg') : require('@/assets/images/icons/noMarker.svg')}" />
                    <span style="margin-left: 5px">Upper case letter</span>
                </li>
                <li style="margin-bottom: 5px">
                    <img class="tooltip-img" src="${this.checkPasswordHasNumbers ? require('@/assets/images/icons/yesMarker.svg') : require('@/assets/images/icons/noMarker.svg')}" />
                    <span style="margin-left: 5px">Number</span>
                </li>
                <li style="margin-bottom: 5px">
                    <img class="tooltip-img" src="${this.checkPasswordSpaces ? require('@/assets/images/icons/yesMarker.svg') : require('@/assets/images/icons/noMarker.svg')}" />
                    <span style="margin-left: 5px">No spaces</span>
                </li>
                <li style="margin-bottom: 5px">
                    <img class="tooltip-img" src="${passwordSymbolsRegExpCheck(this.signupData.password) ? require('@/assets/images/icons/yesMarker.svg') : require('@/assets/images/icons/noMarker.svg')}" />
                    <span style="margin-left: 5px">Only latin letters, numbers and special symbols</span>
                </li>
            </ul>
            `;
        },
        setAccessType(type) {
            if (this.formDisabled) {
                return;
            }
            this.signupData.accessType = type;
        },
        togglePassword(variant, id) {
            if (id.indexOf('Confirm') === -1) {
                if (variant) {
                    document.getElementById(id).getElementsByTagName('input')[0].type = 'text';
                    this.hiddenPassword = false;
                } else {
                    document.getElementById(id).getElementsByTagName('input')[0].type = 'password';
                    this.hiddenPassword = true;
                }
            } else if (variant) {
                document.getElementById(id).getElementsByTagName('input')[0].type = 'text';
                this.hiddenPasswordConfirm = false;
            } else {
                document.getElementById(id).getElementsByTagName('input')[0].type = 'password';
                this.hiddenPasswordConfirm = true;
            }
        },
        async signup() {
            try {
                this.isSignUpSubmitDisabled = true;
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch('Auth/signup', this.signupData);
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Confirmation code on the way to your email');
                await this.$router.push({
                    path: '/signup-confirm',
                    query: {
                        isFirstTime: true,
                    },
                });
            } catch (error) {
                this.resetRecaptcha();
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
                this.isSignUpSubmitDisabled = false;
            }
        },
        checkFirstLetter(str) {
            const letter = str[0];
            return letter.toUpperCase() !== letter.toLowerCase();
        },
        showConfirm() {
            this.signupData.firstName = this.signupData.firstName.trim();
            this.signupData.lastName = this.signupData.lastName.trim();
            this.signupData.inviteCode = this.signupData.inviteCode.trim();
            this.signupData.email = this.signupData.email.trim();
            this.$v.$touch();
            if (!this.signupData.acceptUserAgreement) {
                this.errors.acceptUserAgreement = true;
                this.$store.dispatch('Notificator/showErrorNotification', 'You have to accept User Agreement');
            }
            if (!this.signupData.recaptchaToken) {
                this.$store.dispatch('Notificator/showErrorNotification', 'Please complete the captcha in sign-up form');
                return;
            }
            if (!this.$v.$invalid) {
                if (this.checkPasswordLength
                    && this.checkPasswordHasNumbers
                    && this.checkPasswordHasUpperCaseSymbol
                    && this.checkPasswordHasLowerCaseSymbol
                    && this.checkPasswordSpaces) {
                    if (this.signupData.password !== this.signupData.passwordConfirm) {
                        this.resetRecaptcha();

                        this.$store.dispatch('Notificator/showErrorNotification', 'Confirm password is different from password');
                    } else if (!(this.signupData.accessType === 'Corporate' || this.checkFirstLetter(this.signupData.firstName))) {
                        this.toggleFirstNameTooltip(true);
                        this.errors.firstName = true;
                    } else if (!(this.signupData.accessType === 'Corporate' || this.checkFirstLetter(this.signupData.lastName))) {
                        this.toggleLastNameTooltip(true);
                        this.errors.lastName = true;
                    } else {
                        this.showSecondStep = true;
                    }
                } else {
                    this.$store.dispatch('Notificator/showErrorNotification', 'Your password does not match all the requirements');
                }
            } else {
                if (this.$v.signupData.email.$invalid) {
                    this.errors.email = true;
                }
                if (this.$v.signupData.password.$invalid) {
                    this.toggleTooltip(true);
                    this.errors.password = true;
                }
                if (this.$v.signupData.passwordConfirm.$invalid) {
                    this.errors.passwordConfirm = true;
                }
                if (this.$v.signupData.firstName.$invalid) {
                    this.toggleFirstNameTooltip(true);
                    this.errors.firstName = true;
                }
                if (this.$v.signupData.lastName.$invalid) {
                    this.toggleLastNameTooltip(true);
                    this.errors.lastName = true;
                }
                if (this.$v.signupData.inviteCode.$invalid) {
                    this.errors.inviteCode = true;
                }
            }
        },
        showForm() {
            this.showSecondStep = false;
        },
        resetRecaptcha() {
            this.signupData.recaptchaToken = null;
            try {
                this.$refs.recaptcha.reset();
            } catch {
                // recaptcha error
            }
        },
        onRecaptchaExpired() {
            this.resetRecaptcha();
        },
        onRecaptchaVerified(token) {
            this.signupData.recaptchaToken = token;
        },
        openWindow(link) {
            window.open(link);
        },
        checkIsInviteCodeValid() {
            this.$v.$touch();
            this.formDisabled = !!this.$v.signupData.inviteCode.$invalid;
        },
        onInputFirstName(e) {
            this.signupData.firstName = e;
            this.errors.firstName = false;
        },
        onInputLastName(e) {
            this.signupData.lastName = e;
            this.errors.lastName = false;
        },
        onInputEmail(e) {
            this.signupData.email = e;
            this.errors.email = false;
        },
        onInputPassword(e) {
            this.signupData.password = e;
            this.errors.password = false;
        },
        onInputPasswordConfirm(e) {
            this.signupData.passwordConfirm = e;
            this.errors.passwordConfirm = false;
        },
    },
    mounted() {
        if (this.$store.getters['Auth/isLogged']) {
            this.$router.push('/terminal');
        }
    },
    watch: {
        // eslint-disable-next-line func-names
        'signupData.inviteCode': function (value) {
            this.checkIsInviteCodeValid();
            if (value !== value.toUpperCase()) {
                this.signupData.inviteCode = value.toUpperCase();
            }
        },
        isThemeDark() {
            // костыль для того, чтобы сменилась тема капчи, так как метод reset не дает искомого результата
            this.showCaptcha = false;
            setTimeout(() => {
                this.showCaptcha = true;
            }, 100);
        },
        showCaptcha() {
            this.signupData.recaptchaToken = null;
        },
    },
};
</script>

<style lang="postcss" module="s">
.accessType {
    width: 100%;
    max-width: 210px;
    margin: 0 auto;
    display: flex;
    border: 1px solid rgba(145, 145, 166, 0.2);
    border-radius: 48px;
    position: relative;
    box-shadow: var(--small-box-shadow);
    &.disabled {
        opacity: .4;
    }
}
.accessType:before {
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background: #646fdb;
    border-radius: 48px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s left;
}
.accessType.active:before {
    left: 50%;
}
.accessType span {
    width: 50%;
    text-align: center;
    padding: 5px 0;
    z-index: 3;
    cursor: pointer;
    transition: color 0.3s;
}
.accessType span.active {
    color: white;
}
.buttonsRow {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--m-xxxl) var(--m-xxxl) var(--m-xxxl);
    column-gap: var(--m-xxl);
}
.row {
    display: flex;
    justify-content: space-between;
    column-gap: var(--m-xxl);
    padding: var(--m-xxxl) var(--m-xxxl) var(--m-xl) var(--m-xxxl);
}
.col {
    display: flex;
    flex-direction: column;
    row-gap: var(--m-xxl);
}
.title {
    font-weight: var(--fw-bold);
    font-size: var(--fs-s);
    line-height: var(--fs-s);
    color: var(--cl-violet);
    margin-bottom: var(--m-s);
}
.value {
    font-weight: var(--fw-regular);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    color: var(--cl-black);
}
.error {
    color: var(--cl-header-orange-2)
}
.codeText {
    font-weight: var(--fw-regular);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    text-align: center;
    color: var(--cl-black);
    width: 100%;
}
.link {
    color: var(--cl-violet);
    text-decoration: underline;
    cursor: pointer;
}
.codeRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--m-xxxl);
    & .codeInput {
        margin-top: var(--m-m);
        width: 100%;
        border: none;
        border-bottom: 1px solid var(--cl-violet);
        text-align: center;
        font-weight: var(--fw-regular);
        font-size: var(--fs-xxxl);
        line-height: var(--fs-xxxl);
        text-transform: uppercase;
        color: var(--cl-violet);
        background-color: var(--cl-white);
    }
}
.checkboxText {
    font-weight: var(--fw-regular);
    font-size: var(--fs-s);
    line-height: var(--fs-s);
    color: var(--cl-black);
}
</style>

<style>
.tooltip-arrow {
    display: none;
}
.tooltip-img {
    width: 12px !important;
    height: 12px !important;
    position: relative;
    bottom: -2px;
}
</style>
